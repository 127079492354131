// @ts-nocheck
import type { LayersSelectOptions } from 'modal/SelectLayers';

import {
  ADDITIONAL_ITEM_MODAL_TYPE,
  COMPARE_TYPE_ENUM,
  EXAMINATION_MODAL_TYPE,
  ONBOARDING_MODAL_TYPE,
  PATIENT_MODAL_TYPE,
  ROUTES,
  SUBSCRIPTION_MODAL_TYPE,
  SURVEY_MODAL_TYPE,
  TEAM_MEMBER_MODAL_TYPE,
  USER_ROLES,
} from './constants';
import { DateFormatter } from './DateFormatter.class';

import dicom from 'assets/files/AltrisAI_Onboarding_Dicom.dcm';
import Modal from 'components/Modal';
import {
  createExaminationRequest,
  deleteExaminationRequest,
  setCompareData,
  updateExaminationRequest,
} from 'redux/actions/examination';
import { closeBlurModal, closeModal, openModal } from 'redux/actions/modal';
import {
  createPatientRequest,
  deletePatientRequest,
  updatePatientRequest,
} from 'redux/actions/patient';
import { unsubscribePlanRequest } from 'redux/actions/subscription';
import {
  deleteTeamMemberRequest,
  inviteTeamMemberRequest,
  reinviteTeamMemberRequest,
} from 'redux/actions/teamMember';
import { userLogoutRequest } from 'redux/actions/userAuth';
import { COMPONENT_MODAL, DEFAULT_MODAL } from 'redux/constants/modal';
import { store } from 'redux/store';
import i18n from 'translation/i18n';
import { ICurrentPlan } from 'types/editProfile';
import { ExportJsonProps } from 'types/examination';
import { IActionUpdatePatient, IPatient, IUpdatePatient } from 'types/patients';
import { SettingsEnum } from 'types/settings';
import { ISubscriptionPrice } from 'types/subscription';
import { ITeamMemberInvite, ITeamMemberInviteActions } from 'types/teamMember';
import { addDaysToDate, ga } from 'utils/helpers';

export const signOutModal = () => {
  store.dispatch(
    openModal(DEFAULT_MODAL, {
      title: i18n.t('modals.sign_out.title'),
      subtitle: i18n.t('modals.sign_out.subtitle'),
      buttons: [
        {
          title: i18n.t('modals.sign_out.cancel'),
          appearance: 'light',
          event: () => store.dispatch(closeModal()),
        },
        {
          title: i18n.t('modals.sign_out.yes'),
          appearance: 'primary',
          event: () => {
            store.dispatch(userLogoutRequest());
            store.dispatch(closeModal());

            console.info('You have been logged out successfully');
          },
        },
      ],
    })
  );
};

export const addPatientModal = () => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={PATIENT_MODAL_TYPE.CREATE_PATIENT}
          title={i18n.t('modals.add_patient.title')}
          subtitle={i18n.t('modals.add_patient.subtitle')}
          confirm={(data: any, action: any) => {
            /** We determine whether we need to create a patient from DICOM data */
            if (data['patient_from_dicom']) {
              store.dispatch(createExaminationRequest(data, action));
              ga(`Add_exam_done`);
              return;
            }

            store.dispatch(createPatientRequest(data, action));
            ga(`Add_patient_done`);
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const addExaminationModal = (patient: any) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={EXAMINATION_MODAL_TYPE.CREATE_EXAMINATION}
          title={i18n.t('modals.add_examination')}
          patient={patient}
          confirm={(values: any, action: any) => {
            store.dispatch(createExaminationRequest(values, action));
            ga(`Add_exam_done`);
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const addEyeExaminationModal = (
  compareType: COMPARE_TYPE_ENUM,
  scan_id?: string,
  onSuccess?: (examination_ids: string[]) => void
) => {
  const userData = store.getState()?.userProfile?.data;

  if (!userData) return;

  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={EXAMINATION_MODAL_TYPE.ADD_EYE}
          title={i18n.t('modals.add_eye_examination.title')}
          subtitle={i18n.t('modals.add_eye_examination.subtitle')}
          choseExistedExam
          scanId={scan_id}
          compareType={compareType} // Check what type of tab active now
          confirm={(values: any) => {
            if (
              !userData?.is_able_to_make_examinations &&
              typeof values['scan'] !== 'object'
            ) {
              // @ts-ignore
              noObjectsLeftModal(userData.plan, 'examinations');
            }

            const updatedValues = {
              ...values,
              patient_id: values.patient_id,
              pid: values.pid,
              compareType: compareType,
            };

            store.dispatch(
              setCompareData(updatedValues, { success: onSuccess })
            );
            store.dispatch(closeModal());
          }}
          reject={() => store.dispatch(closeModal())}
        />
      ),
    })
  );
};

export const editScanDescriptionModal = (
  subtitle: string,
  content: string,
  scanId?: string
) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={EXAMINATION_MODAL_TYPE.EDIT_SCAN_DESCRIPTION}
          subtitle={subtitle}
          content={content}
          scanId={scanId}
        />
      ),
    })
  );
};

export const inviteTeamMemberModal = () => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={TEAM_MEMBER_MODAL_TYPE.INVITE_TEAM_MEMBER}
          title={i18n.t('modals.invite_team_member.title')}
          subtitle={i18n.t('modals.invite_team_member.subtitle')}
          confirm={(
            data: ITeamMemberInvite,
            action: ITeamMemberInviteActions
          ) => {
            store.dispatch(inviteTeamMemberRequest(data, action));
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const deleteTeamMemberModal = (id: number, full_name: string) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={TEAM_MEMBER_MODAL_TYPE.DELETE_TEAM_MEMBER}
          title={i18n.t('modals.delete_team_member.title')}
          subtitle={i18n.t('modals.delete_team_member.subtitle')}
          text={full_name}
          confirm={() => {
            store.dispatch(deleteTeamMemberRequest(id));
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const reinviteTeamMember = (id: number, full_name: string) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={TEAM_MEMBER_MODAL_TYPE.REINVITE_TEAM_MEMBER}
          title={i18n.t('modals.reinvite_team_member.title')}
          subtitle={i18n.t('modals.reinvite_team_member.subtitle')}
          text={full_name}
          confirm={() => {
            store.dispatch(reinviteTeamMemberRequest(id));
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const deletePatientModal = (id: number, title: string | null) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={PATIENT_MODAL_TYPE.DELETE_PATIENT}
          title={i18n.t('modals.delete_patient.title')}
          subtitle={i18n.t('modals.delete_patient.subtitle')}
          text={title}
          confirm={() => {
            store.dispatch(deletePatientRequest(id));
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

interface UpdatePatient {
  id: number;
  data: IUpdatePatient;
}

export const updatePatientModal = (id: number, createdData: IPatient) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={PATIENT_MODAL_TYPE.UPDATE_PATIENT}
          title={i18n.t('modals.update_patient.title')}
          subtitle={i18n.t('modals.update_patient.subtitle')}
          patient={createdData}
          patientId={id}
          confirm={(
            updateData: UpdatePatient,
            action: IActionUpdatePatient
          ) => {
            store.dispatch(updatePatientRequest(updateData, action));
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const showCurrentPlanModal = (currentPlan: any) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={SUBSCRIPTION_MODAL_TYPE.CURRENT_PLAN}
          currentPlan={currentPlan}
        />
      ),
    })
  );
};

export const noObjectsLeftModal = (
  currentPlan: ICurrentPlan,
  leftType: 'examinations' | 'patients',
  model?: USER_ROLES
) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={SUBSCRIPTION_MODAL_TYPE.NO_OBJECTS_LEFT}
          currentPlan={currentPlan}
          leftType={leftType}
          model={model}
        />
      ),
    })
  );
};

export const unsubscribeModal = () => {
  store.dispatch(
    openModal(DEFAULT_MODAL, {
      title: i18n.t('modals.unsubscribe.title'),
      subtitle: i18n.t('modals.unsubscribe.subtitle'),
      buttons: [
        {
          title: i18n.t('modals.unsubscribe.cancel'),
          appearance: 'light',
          event: () => store.dispatch(closeModal()),
        },
        {
          title: i18n.t('modals.unsubscribe.yes'),
          appearance: 'primary',
          event: () => {
            store.dispatch(unsubscribePlanRequest());
            store.dispatch(closeModal());
          },
        },
      ],
    })
  );
};

export const subscribeFakePlanModal = () => {
  store.dispatch(
    openModal(DEFAULT_MODAL, {
      title: i18n.t('modals.subscribe_fake.title'),
      subtitle: i18n.t('modals.subscribe_fake.subtitle'),
      buttons: [
        {
          title: i18n.t('modals.subscribe_fake.cancel'),
          appearance: 'light',
          event: () => store.dispatch(closeModal()),
        },
        {
          title: i18n.t('modals.subscribe_fake.yes'),
          appearance: 'primary',
          event: () => {
            store.dispatch(closeBlurModal());
            store.dispatch(closeModal());
          },
        },
      ],
    })
  );
};

/**
 * @param {*} action need for @grace_period.
 * When you register and log in for the first time, you can
 * to choose a plan.
 */
export const subscribeNewPlanModal = (
  currentPlan: ISubscriptionPrice | undefined,
  action?: any
) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={SUBSCRIPTION_MODAL_TYPE.SUBSCRIBE_PLAN}
          plan={currentPlan}
          action={action}
        />
      ),
    })
  );
};

export const buyAdditionalItemModal = (additionalItems: any, action: any) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={ADDITIONAL_ITEM_MODAL_TYPE.EXAMINATIONS}
          data={additionalItems}
          action={action}
        />
      ),
    })
  );
};

/**
 * Renew modal for B2B & B2C users
 *
 * @param text: text under the mail where you can send email to support (only for
 * B2B account holder);
 * @param buttonText: not required option, By default is a "OK";
 * @param to: only for B2B role, by this param we redirect to other page, or open
 * stripe modal if we use B2C account;
 * @param legacyPlan: if we use legacy plan, we show other modal;
 */

export const renewSubscribeWarningModal = (legacyPlan: boolean) => {
  if (legacyPlan) {
    return store.dispatch(
      openModal(COMPONENT_MODAL, {
        component: (
          <Modal
            type={SUBSCRIPTION_MODAL_TYPE.RENEW_WARNING}
            title={i18n.t('modals.renew_subscribe_warning.title1')}
            subtitle={i18n.t('modals.renew_subscribe_warning.subtitle1')}
            buttonText={i18n.t('modals.renew_subscribe_warning.button_text1')}
            to={ROUTES.PROFILE.SUBSCRIPTION}
          />
        ),
      })
    );
  }

  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={SUBSCRIPTION_MODAL_TYPE.RENEW_WARNING}
          title={i18n.t('modals.renew_subscribe_warning.title2')}
          subtitle={i18n.t('modals.renew_subscribe_warning.subtitle2')}
          buttonText={i18n.t('modals.renew_subscribe_warning.button_text2')}
        />
      ),
    })
  );
};

export const renewSubscribeWarningB2BModal = () => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={SUBSCRIPTION_MODAL_TYPE.RENEW_WARNING}
          title={i18n.t('modals.renew_subscribe_warning_b2b.title')}
          subtitle={i18n.t('modals.renew_subscribe_warning_b2b.subtitle')}
          buttonText={i18n.t('modals.renew_subscribe_warning_b2b.button_text')}
          to={ROUTES.PROFILE.CONTACT_US}
        />
      ),
    })
  );
};

export const renewSubscribeRejectModal = (userCurrentPlan: ICurrentPlan) => {
  const endingDate = new Date(userCurrentPlan.current_period_start);

  if (userCurrentPlan.is_legacy) {
    return store.dispatch(
      openModal(COMPONENT_MODAL, {
        component: (
          <Modal
            type={SUBSCRIPTION_MODAL_TYPE.RENEW_REJECT}
            title={i18n.t('modals.renew_subscribe_reject.title1')}
            subtitle={i18n.t('modals.renew_subscribe_reject.subtitle1', {
              date: DateFormatter.getDate(
                store
                  .getState()
                  .settings.data.find(
                    (setting: { slug: SettingsEnum }) =>
                      setting?.slug === SettingsEnum.TIME_FORMAT
                  )?.status,
                addDaysToDate(endingDate, 180)
              ),
            })}
            buttonText={i18n.t('modals.renew_subscribe_reject.button_text1')}
            to={ROUTES.PROFILE.SUBSCRIPTION}
            cantClose
          />
        ),
      })
    );
  }

  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          title={i18n.t('modals.renew_subscribe_reject.title2')}
          subtitle={i18n.t('modals.renew_subscribe_reject.subtitle2', {
            date: DateFormatter.getDate(
              store
                .getState()
                .settings.data.find(
                  (setting: { slug: SettingsEnum }) =>
                    setting?.slug === SettingsEnum.TIME_FORMAT
                )?.status,
              addDaysToDate(endingDate, 180)
            ),
          })}
          buttonText={i18n.t('modals.renew_subscribe_reject.button_text2')}
          type={SUBSCRIPTION_MODAL_TYPE.RENEW_REJECT}
          cantClose={true}
        />
      ),
    })
  );
};

export const renewSubscribeRejectB2BModal = (userCurrentPlan: any) => {
  const endingDate = new Date(userCurrentPlan.b2b_past_due_from);

  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          title={i18n.t('modals.renew_subscribe_reject_b2b.title')}
          subtitle={i18n.t('modals.renew_subscribe_reject_b2b.subtitle', {
            date: DateFormatter.getDate(
              store
                .getState()
                .settings.data.find(
                  (setting: { slug: SettingsEnum }) =>
                    setting?.slug === SettingsEnum.TIME_FORMAT
                )?.status,
              addDaysToDate(endingDate, 180)
            ),
          })}
          buttonText={i18n.t('modals.renew_subscribe_reject_b2b.button_text')}
          type={SUBSCRIPTION_MODAL_TYPE.RENEW_REJECT}
          cantClose
          to={ROUTES.PROFILE.CONTACT_US}
        />
      ),
    })
  );
};

export const analyzeScansHandler = (options?: any) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={EXAMINATION_MODAL_TYPE.ANALYSE_SCANS}
          title={options?.title ?? i18n.t('modals.analyze_scans.title')}
          subtitle={
            options?.subtitle
              ? options.subtitle
              : i18n.t('modals.analyze_scans.subtitle')
          }
          analyseAll={options?.analyseAll}
          examId={options?.exam_id}
          options={options}
          confirm={() => {
            store.dispatch(closeModal());
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const openLayersSelectModal = (options: LayersSelectOptions) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={EXAMINATION_MODAL_TYPE.SELECT_LAYERS}
          title={i18n.t('modals.select_retina_layers')}
          options={options}
          confirm={() => {
            store.dispatch(closeModal());
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const deleteExaminationModal = (id: string, title: string) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={EXAMINATION_MODAL_TYPE.DELETE_EXAMINATION}
          title={i18n.t('modals.delete_examination.title')}
          subtitle={i18n.t('modals.delete_examination.subtitle')}
          text={title}
          confirm={() => {
            store.dispatch(deleteExaminationRequest({ exam_id: id }));
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const updateExaminationModal = (examinationData: any) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={EXAMINATION_MODAL_TYPE.UPDATE_EXAMINATION}
          title={i18n.t('modals.update_examination.title')}
          subtitle={i18n.t('modals.update_examination.subtitle')}
          examination={examinationData}
          confirm={(updatedExaminationData: any) => {
            store.dispatch(updateExaminationRequest(updatedExaminationData));
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const openOnBoardingTourModal = () => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={ONBOARDING_MODAL_TYPE.ONBOARDING_TOUR}
          title={i18n.t('modals.onboarding_tour.title')}
          subtitle={i18n.t('modals.onboarding_tour.subtitle')}
          extraFunctional={downloadDCMModal}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const openJsonExamExportModal = (props: ExportJsonProps) => {
  const { title, subtitle, exam_id } = props;
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={EXAMINATION_MODAL_TYPE.EXPORT_JSON}
          title={title ? title : i18n.t('modals.json_exam_export.title')}
          subtitle={
            subtitle ? subtitle : i18n.t('modals.json_exam_export.subtitle')
          }
          exam_id={exam_id}
          confirm={() => store.dispatch(closeModal())}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const unsubscribeSurveyModal = () => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={SURVEY_MODAL_TYPE.UNSUBSCRIBE}
          title={i18n.t('modals.unsubscribe_survey.title')}
          subtitle={i18n.t('modals.unsubscribe_survey.subtitle')}
          confirm={() => {
            console.info('unsubscribeSurveyModal');
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const bookingMeetingModal = () => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={SURVEY_MODAL_TYPE.BOOKING_MEETING}
          title={i18n.t('modals.booking_meeting.title')}
          subtitle={i18n.t('modals.booking_meeting.subtitle')}
          confirm={() => {
            console.info('bookingMeetingModal');
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const downloadDCMModal = () => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={ONBOARDING_MODAL_TYPE.DOWNLOAD_DCM}
          title={i18n.t('modals.download_dicom.title')}
          subtitle={i18n.t('modals.download_dicom.subtitle')}
          confirm={() => {
            window.location.href = dicom;
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const downloadDCMSuccessModal = () => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={ONBOARDING_MODAL_TYPE.DOWNLOAD_SUCCESS}
          title={i18n.t('modals.download_dicom_success.title')}
          subtitle={i18n.t('modals.download_dicom_success.subtitle')}
          confirm={() => {
            window.location.href = dicom;
          }}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const changeSingleScanTabModal = () => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={ONBOARDING_MODAL_TYPE.CHANGE_SINGLE_SCAN_TAB}
          title={i18n.t('modals.change_single_scan_tab')}
          reject={() => {
            store.dispatch(closeModal());
          }}
        />
      ),
    })
  );
};

export const glaucomaDisclaimerModal = (className: string) => {
  store.dispatch(
    openModal(COMPONENT_MODAL, {
      component: (
        <Modal
          type={EXAMINATION_MODAL_TYPE.GLAUCOMA_DISCLAIMER}
          title={i18n.t('modals.glaucoma_disclaimer')}
          cantClose
          confirm={() => {
            store.dispatch(closeModal());
          }}
          className={className}
          waitForData={false}
        />
      ),
    })
  );
};
