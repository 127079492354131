import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError, TResponseSuccess } from '../../types';

import { TGlaucomaExam } from 'types/glaucoma/TGlaucomaExam';

/**
 * @function getData
 * @alias getData
 * @see https://github.com/axios/axios
 */

export interface TAPIGetGlauExams {
  TError: TResponseError;
  TSuccess: TResponseSuccess<TGlaucomaExam[]>;
  TParams: {
    patientId: string | number;
  };
}

export type TError = TAPIGetGlauExams['TError'];
export type TSuccess = TAPIGetGlauExams['TSuccess'];
export type TParams = TAPIGetGlauExams['TParams'];

export async function getGlaucomaExaminations(
  this: BaseRESTClient,
  params: TParams,
  config?: AxiosRequestConfig
) {
  try {
    const { data } = await this.client.get<TSuccess>(
      `/examination/glaucoma/get-patient-all-examinations/${params.patientId}`,
      config
    );
    // get eyes array
    return data;
  } catch (error: any | TResponseError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
