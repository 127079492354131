import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError, TResponseSuccess } from '../../types';

import { TGlaucomaExam } from 'types/glaucoma/TGlaucomaExam';

/**
 * @function updateGlaucomaProgState
 * @alias updateGlaucomaProgState
 * @see https://github.com/axios/axios
 */

export interface TAPIUpdGlauProgSt {
  TError: TResponseError;
  TSuccess: TResponseSuccess<TGlaucomaExam>;
  TPayload: string[];
}

export type TError = TAPIUpdGlauProgSt['TError'];
export type TSuccess = TAPIUpdGlauProgSt['TSuccess'];
export type TPayload = TAPIUpdGlauProgSt['TPayload'];

// gen uni key for query cache access
export const updateGlaucomaProgStateKey = (payload: TPayload) => [
  'glaucoma',
  updateGlaucomaProgState.name,
  payload,
];

export async function updateGlaucomaProgState(
  this: BaseRESTClient,
  payload: TPayload,
  config?: AxiosRequestConfig
): Promise<TSuccess> {
  try {
    const { data } = await this.client.put<TSuccess>(
      `/examination/glaucoma/update-state`,
      { examination_ids: payload },
      config
    );
    return data;
  } catch (error: any | TError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
