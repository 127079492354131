import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError, TResponseSuccess } from '../../types';

import { TGlaucomaExam } from 'types/glaucoma/TGlaucomaExam';

/**
 * @function saveOppositeEye
 * @alias saveOppositeEye
 * @see https://github.com/axios/axios
 */

export interface TAPIGetOppEyeSt {
  TError: TResponseError;
  TSuccess: {
    opposite_eye: TGlaucomaExam | null;
    data: TGlaucomaExam;
  };
  TParams: {
    examId: string;
  };
}

export type TError = TAPIGetOppEyeSt['TError'];
export type TSuccess = TAPIGetOppEyeSt['TSuccess'];
export type TParams = TAPIGetOppEyeSt['TParams'];

export async function getOppositeEyeState(
  this: BaseRESTClient,
  params: TParams,
  config?: AxiosRequestConfig
) {
  try {
    const { data } = await this.client.get<TSuccess>(
      `/examination/glaucoma/${params.examId}/eye-state`,
      config
    );
    return data;
  } catch (error: any | TError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
