import { t } from 'i18next';
import { put, fork, takeEvery, call, delay } from 'redux-saga/effects';

import { createPatient } from 'api/patient';
import { createMockPatient } from 'components/Modal/OnboardingTour/mockData/mockData';
import { closeModal } from 'redux/actions/modal';
import {
  createPatientError,
  createPatientSuccess,
  getPatientRequest,
  getPatientSuccess,
} from 'redux/actions/patient';
import { patientConstants } from 'redux/constants/patient';
import {
  PatientsTypes,
  ICreatePatient,
  IActionCreatePatient,
} from 'types/patients';
import notify from 'utils/toast';

interface ICreatePatientWorker {
  type: PatientsTypes;
  payload: ICreatePatient;
  action: IActionCreatePatient;
}

function* createPatientWatcher() {
  // @ts-ignore
  yield takeEvery(patientConstants.CREATE_PATIENT_REQUEST, createPatientWorker);
}

function* createPatientWorker({ payload, action }: ICreatePatientWorker) {
  try {
    if (action?.onBoarding) {
      /**
       * imitate the server delay
       */
      yield delay(250);
      yield put(closeModal());

      /**
       * create mock patient for the onboarding tour
       */
      yield put(getPatientSuccess(createMockPatient(payload) as any));
      return;
    }

    const { data: createdPatient } = yield createPatient(payload);

    /**
     * Save the last patient id in the local storage for
     * the scroll in the dashboard page
     */
    yield call(
      [localStorage, localStorage.setItem],
      'last_patient',
      createdPatient.data.id
    );

    yield document
      .querySelector(`[data-patient-id='${createdPatient.data.id}']`)
      ?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

    yield put(getPatientRequest());
    yield put(createPatientSuccess());
    yield notify('success', t('notifications.patient_was_added_successfully'));

    /**
     * Setting a new patient as a default in the examinationModal
     * form, after clicking Add examination in the createPatientModal
     */
    if (action?.addExaminationModal) {
      yield action.addExaminationModal(createdPatient.data);
      return;
    }

    yield put(closeModal());
  } catch (error) {
    yield put(createPatientError(error));
    yield notify('error', t('notifications.something_went_wrong'));
    yield put(closeModal());
  }
}

export default function* createPatientSaga() {
  yield fork(createPatientWatcher);
}
