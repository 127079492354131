import { t } from 'i18next';
import { fork, put, takeEvery } from 'redux-saga/effects';

import { getSinglePatient } from 'api/patient';
import {
  getPatientExamError,
  getPatientExamSuccess,
} from 'redux/actions/patient';
import { patientConstants } from 'redux/constants/patient';
import { PatientsTypes } from 'types/patients';
import notify from 'utils/toast';

interface IGetPatientExamWorker {
  type: PatientsTypes;
  payload: number;
}

function* getPatientExamWatcher() {
  yield takeEvery(
    //@ts-ignore
    patientConstants.GET_PATIENT_EXAMINATIONS_REQUEST,
    getPatientExamWorker
  );
}

function* getPatientExamWorker({ payload }: IGetPatientExamWorker) {
  try {
    const { data } = yield getSinglePatient(payload);
    yield put(getPatientExamSuccess(data?.data?.examinations));
  } catch (error) {
    yield put(getPatientExamError(error));
    yield notify('error', t('notifications.something_went_wrong'));
  }
}

export default function* getAllPatientExam() {
  yield fork(getPatientExamWatcher);
}
