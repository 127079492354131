import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError } from '../../types';

import { TGlaucomaExam } from 'types/glaucoma/TGlaucomaExam';

/**
 * @function getGlaucomaData
 * @alias getGlaucomaData
 * @see https://github.com/axios/axios
 */

export interface TAPIGetGlauData {
  TError: TResponseError;
  TSuccess: {
    data: TGlaucomaExam;
    opposite_eye: TGlaucomaExam | null;
  };
  TParams: {
    examId: string;
  };
}

export type TError = TAPIGetGlauData['TError'];
export type TSuccess = TAPIGetGlauData['TSuccess'];
export type TParams = TAPIGetGlauData['TParams'];

// get glaucoma eyes by exam
export async function getGlaucomaData(
  this: BaseRESTClient,
  params: TParams,
  config?: AxiosRequestConfig
): Promise<TSuccess> {
  try {
    const { data } = await this.client.get<TSuccess>(
      `/examination/glaucoma/${params.examId}`,
      config
    );
    return data;
  } catch (error: any | TError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
