import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Text from 'components/Text';

import styles from './ErrorFallback.module.scss';

interface IErrorFallbackProps {
  resetErrorBoundary: () => void;
}

function ErrorFallback({ resetErrorBoundary }: IErrorFallbackProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'components.error_fallback',
  });

  return (
    <div role='alert' className={styles.error}>
      <div className={styles['error-wrapper']}>
        <Text className={styles.text} align='center'>
          {t('something_went_wrong')}
        </Text>
        <Button className={styles.button} onClick={resetErrorBoundary}>
          {t('fix_problem')}
        </Button>
      </div>
    </div>
  );
}

ErrorFallback.displayName = 'ErrorFallback';

export default ErrorFallback;
