import { t } from 'i18next';
import { call, delay, fork, put, takeEvery } from 'redux-saga/effects';

import { createExamination } from 'api/examination';
import { createMockExamination } from 'components/Modal/OnboardingTour/mockData/mockData';
import {
  createExaminationError,
  createExaminationSuccess,
} from 'redux/actions/examination';
import { closeModal } from 'redux/actions/modal';
import { getPatientRequest, getPatientSuccess } from 'redux/actions/patient';
import { getUserProfileRequest } from 'redux/actions/userProfile';
import { ExamActionTypes } from 'redux/constants/examination';
import { IReduxAction } from 'redux/types/examination';
import { ICreateExamination } from 'types/examination';
import { CREATE_EXAMINATION_FLOW } from 'utils/constants';
import notify from 'utils/toast';

function* createExaminationWatcher() {
  yield takeEvery(
    ExamActionTypes.CREATE_EXAMINATION_REQUEST,
    createExaminationWorker
  );
}

interface OnboardingAction extends IReduxAction {
  onBoarding?: boolean;
}

interface ICreateExamWorker {
  type: ExamActionTypes;
  payload: ICreateExamination;
  action: OnboardingAction;
}

function* createExaminationWorker({ payload, action }: ICreateExamWorker) {
  const {
    pid,
    patient_id,
    width,
    eye,
    note,
    file,
    examination_date,
    flow,
    patient_from_dicom,
  } = payload;

  try {
    const formData = new FormData();

    for (let i = 0; 'length' in file && i < file.length; i++) {
      // @ts-ignore
      formData.append('file[]', file[i]);
    }

    // FIXME: NEED REMOVE "AS" FOR FUTURE ITERATION
    /** Checking what creation flow we got.  */
    if (flow === CREATE_EXAMINATION_FLOW.ZIPPED_DICOM_IN_PATIENT) {
      if (patient_from_dicom) {
        formData.append(
          'flow',
          CREATE_EXAMINATION_FLOW.ZIPPED_DICOM_OUT_OF_PATIENT
        );
        formData.append('note', note ?? '');
      } else {
        formData.append(
          'flow',
          CREATE_EXAMINATION_FLOW.ZIPPED_DICOM_IN_PATIENT
        );
        formData.append('pid', pid ?? '');
        formData.append('patient_id', patient_id as unknown as string);
        formData.append('note', note ?? '');
      }
    }

    if (flow === CREATE_EXAMINATION_FLOW.DICOM_IN_PATIENT) {
      if (patient_from_dicom) {
        formData.append('flow', CREATE_EXAMINATION_FLOW.DICOM_OUT_OF_PATIENT);
        formData.append('note', note ?? '');
      } else {
        formData.append('flow', CREATE_EXAMINATION_FLOW.DICOM_IN_PATIENT);
        formData.append('pid', pid ?? '');
        formData.append('patient_id', patient_id as unknown as string);
        formData.append('note', note ?? '');
      }
    }

    if (flow === CREATE_EXAMINATION_FLOW.IMAGES_IN_PATIENT) {
      formData.append('pid', pid);
      formData.append('patient_id', patient_id as unknown as string);
      formData.append('width', width);
      formData.append('eye', eye);
      formData.append('examination_date', String(examination_date));
      formData.append('flow', flow);
      formData.append('note', note ?? '');
    }

    if (action?.onBoarding) {
      /**
       * imitate the server delay
       */
      yield delay(250);
      yield put(closeModal());

      /**
       * create mock patient for the onboarding tour
       */
      yield put(getPatientSuccess(createMockExamination() as any));
      yield put(createExaminationSuccess());
      return;
    }

    const { data: createdExaminationData } = yield createExamination(
      formData as unknown as ICreateExamination
    );

    if (createdExaminationData.status === 'Error') {
      throw new Error(createdExaminationData.message);
    }

    yield call(
      [localStorage, localStorage.setItem],
      'last_patient',
      createdExaminationData.data[0].patient_id
    );

    yield document
      .querySelector(
        `[data-patient-id='${createdExaminationData.data[0].patient_id}']`
      )
      ?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

    yield put(getPatientRequest());
    yield put(createExaminationSuccess());
    yield notify(
      'success',
      t('notifications.examination_created_successfully')
    );

    /**
     * Need to update user profile after creating an examination
     * for example updating the number of examinations in the header
     * or permissions
     */
    yield put(getUserProfileRequest());
    yield put(closeModal());

    // eslint-disable-next-line prettier/prettier
  } catch (error:any) {
    yield put(createExaminationError('Error'));
    yield notify('error', error.message);
    yield put(closeModal());
  }
}

export default function* createExaminationSaga() {
  yield fork(createExaminationWatcher);
}
