import { t } from 'i18next';
import { delay, fork, put, select, takeEvery } from 'redux-saga/effects';

import { EXAMINATION, PATIENT } from 'api/endpoints';
import {
  createExamination,
  getExamination,
  getExaminationStatus,
} from 'api/examination';
import { checkExistedData } from 'pages/Examination/helpers';
import {
  ICompareExamAction,
  setCompareBothSuccess,
  setCompareCompSuccess,
  setCompareDataError,
  setCompareDataSuccess,
  setCompareProgressScansDataSuccess,
  updateProgressionDataRequest,
  updateProgressionStateRequest,
} from 'redux/actions/examination';
import { closeModal, openModal } from 'redux/actions/modal';
import { ExamActionTypes } from 'redux/constants/examination';
import { DEFAULT_MODAL } from 'redux/constants/modal';
import { getExamData } from 'redux/selectors/examination/getExamData/getExamData';
import { patientsSelector } from 'redux/selectors/patients';
import { store } from 'redux/store';
import { ICompareExam } from 'types/examination';
import { IPatient } from 'types/patients';
import { revalidatePaths } from 'utils/apiCache';
import { COMPARE_TYPE, COMPARE_TYPE_ENUM } from 'utils/constants';
import { addEyeExaminationModal } from 'utils/openModals';
import notify from 'utils/toast';

function* compareExaminationWatcher() {
  yield takeEvery(
    ExamActionTypes.COMPARE_EXAMINATION_REQUEST,
    compareExaminationWorker
  );
}

interface ICompareExamWorker {
  type: ExamActionTypes;
  payload: ICompareExam;
  action?: ICompareExamAction;
}

function* compareExaminationWorker({ payload, action }: ICompareExamWorker) {
  action?.default?.();

  try {
    let scanId = payload?.scan?.id;

    if (payload?.file) {
      const { pid, patient_id, eye, file, width, examination_date, flow } =
        payload;

      const formData = new FormData();

      if (flow === 'imagesInPatientContext') {
        formData.append('eye', eye);
        formData.append('width', width);
        formData.append('examination_date', String(examination_date));
      }

      formData.append('flow', flow);
      formData.append('pid', pid);
      formData.append('patient_id', patient_id as unknown as string);

      if ('length' in file && file.length) {
        for (let i = 0; 'length' in file && i < file.length; i++) {
          // @ts-ignore
          formData.append('file[]', file[i]);
        }
      } else {
        formData.append('file', file as Blob);
      }

      // @ts-ignore
      const { data } = yield createExamination(formData);

      scanId = data?.data?.[0]?.id;

      const { data: status } = yield getExaminationStatus({ exam_id: scanId });
      let statusTest = status.data.status;

      // FIXME: When we exit from account we have requests | Vladyslav Tsyvinda
      while (statusTest === 'processing') {
        yield delay(3000);
        const { data: status } = yield getExaminationStatus({
          exam_id: scanId,
        });

        statusTest = status.data.status;
      }

      if (statusTest === 'failed') {
        throw 'Examination failed';
      }

      const { data: patientsOldData } = yield select(patientsSelector);

      const patientId = patientsOldData.patients.find((item: IPatient) => {
        return item.examinations?.some(({ id }) => id === scanId);
      });

      yield revalidatePaths([
        EXAMINATION.EXAMINATION_ID(scanId),
        EXAMINATION.GET_PROGRESSION_STATE(scanId),
        PATIENT.GET_ALL_PATIENTS,
        PATIENT.PATIENT_ID(patientId),
      ]);

      yield notify(
        'success',
        t('notifications.examination_created_successfully')
      );
    }

    const { data: examination } = yield getExamination({ exam_id: scanId });

    if (!checkExistedData(examination.data)) {
      throw 'No data';
    }

    if (examination.status === 'Error') {
      throw new Error(examination.message);
    }

    const openInfoModal = ({
      title,
      subtitle,
      compareType,
    }: {
      title: string;
      subtitle: string;
      compareType: COMPARE_TYPE_ENUM;
    }) => {
      store.dispatch(
        openModal(DEFAULT_MODAL, {
          title: title,
          subtitle: subtitle,
          buttons: [
            {
              title: 'Cancel',
              appearance: 'light',
              event: () => store.dispatch(closeModal()),
            },
            {
              title: 'Load another',
              appearance: 'primary',
              event: () => {
                store.dispatch(closeModal());
                store.dispatch(addEyeExaminationModal(compareType));
              },
            },
          ],
        })
      );
    };

    // @ts-ignore
    const oldData = yield select(getExamData);

    switch (payload?.compareType) {
      case COMPARE_TYPE.BOTH: {
        if (oldData?.compareBoth?.[0].eye === examination.data.eye) {
          openInfoModal({
            title: 'Ooops…',
            subtitle: `You’ve tried to upload the same eye. In section Both eyes you should select opposite eye to review them. Please, upload DICOM / Images with opposite eye.`,
            compareType: payload.compareType,
          });

          notify('error', t('notifications.you_tried_upload_same_eye'));

          yield put(setCompareDataSuccess());
          break;
        }

        yield put(
          setCompareBothSuccess([oldData?.compareBoth?.[0], examination.data])
        );
        break;
      }

      case COMPARE_TYPE.COMPARISON: {
        if (oldData?.compareComparison?.[0].eye !== examination.data.eye) {
          openInfoModal({
            title: 'Ooops…',
            subtitle: `You’ve tried to upload opposite eye. In section Comparison you should select same eye to compare them. Please, upload DICOM / Images with the same eye.`,
            compareType: payload.compareType,
          });

          notify('error', t('notifications.you_tried_upload_opposite_eye'));

          yield put(setCompareDataSuccess());
          break;
        }

        yield put(
          setCompareCompSuccess([
            oldData?.compareComparison?.[0],
            examination.data,
          ])
        );
        break;
      }

      case COMPARE_TYPE.PROGRESSION: {
        if (
          oldData?.compareProgress.examsData?.[0].eye !== examination.data.eye
        ) {
          openInfoModal({
            title: 'Ooops…',
            subtitle: `You’ve tried to upload opposite eye. In section Progression you should select opposite eye to track progression. Please, upload DICOM with the same eye.`,
            compareType: payload.compareType,
          });

          notify('error', t('notifications.you_tried_upload_opposite_eye'));

          yield put(setCompareDataSuccess());
          break;
        }

        const newData = [
          ...oldData.compareProgress.examsData,
          examination.data,
        ];

        for (const exam of newData) {
          if (
            !exam?.etdrs &&
            exam.able_to_get_statistics &&
            exam.has_default_etdrs_statistics
          ) {
            yield put(updateProgressionDataRequest({ exam_id: exam.id }));
          }
        }

        yield put(
          updateProgressionStateRequest(newData.map((exam) => exam.id))
        );

        yield put(
          setCompareProgressScansDataSuccess({
            examsData: newData,
          })
        );

        action?.success?.([
          // @ts-ignore
          ...oldData.compareProgress.examsData.map(({ id }) => id),
          examination.data.id,
        ]);
        break;
      }
    }
  } catch (error: any) {
    const errorMessage =
      error.message || t('notifications.something_went_wrong');
    action?.error?.();
    console.error(error);
    yield put(setCompareDataError('Something go wrong'));

    if (error === 'It is not possible to do more examinations') {
      yield notify('error', t('notifications.limit_examination_is_reached'));
      return;
    }

    if (error === 'Examination failed') {
      yield notify('error', t('notifications.examination_failed'));
      return;
    }
    if (error === 'No data') {
      yield notify(
        'error',
        t('notifications.examinations_have_not_enough_data')
      );
      return;
    }

    yield notify('error', errorMessage);
  }
}

export default function* compareExaminationSaga() {
  yield fork(compareExaminationWatcher);
}
