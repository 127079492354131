import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError, TResponseSuccess } from '../../types';

/**
 * @function saveOppositeEyeState
 * @alias saveOppositeEyeState
 * @see https://github.com/axios/axios
 */

export interface TAPISaveOppEyeSt {
  TError: TResponseError;
  TSuccess: TResponseSuccess;

  TParams: {
    examId: string;
  };

  TPayload: {
    oppExamId?: string;
  };
}

export type TError = TAPISaveOppEyeSt['TError'];
export type TSuccess = TAPISaveOppEyeSt['TSuccess'];
export type TParams = TAPISaveOppEyeSt['TParams'];
export type TPayload = TAPISaveOppEyeSt['TPayload'];

export async function saveOppositeEyeState(
  this: BaseRESTClient,
  params: TParams,
  payload: TPayload,
  config?: AxiosRequestConfig
): Promise<TSuccess> {
  const ids = [params.examId];
  if (payload?.oppExamId) ids.push(payload?.oppExamId);
  try {
    const { data } = await this.client.post<TSuccess>(
      `/examination/glaucoma/${params.examId}/eye-state`,
      {
        examination_ids: ids,
      },
      config
    );
    return data;
  } catch (error: any | TError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
