import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError, TResponseSuccess } from '../../types';

import { TExamination } from 'types/examination';

/**
 * @function getExamination
 * @alias getExamination
 * @see https://github.com/axios/axios
 */

export interface TAPIGetExam {
  TError: TResponseError;
  TSuccess: TExamination;
  TParams: {
    examId: string;
  };
}
export interface TGetExaminationParams {
  examId: string;
}

export type TError = TAPIGetExam['TError'];
export type TSuccess = TAPIGetExam['TSuccess'];
export type TParams = TAPIGetExam['TParams'];

export async function getExamination(
  this: BaseRESTClient,
  params: TGetExaminationParams,
  config?: AxiosRequestConfig
): Promise<TSuccess> {
  try {
    const { data } = await this.client.get<TResponseSuccess<TSuccess>>(
      `/examination/${params.examId}`,
      config
    );
    return data.data;
  } catch (error: any | TError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
