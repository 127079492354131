import AltrisRESTClient from './AltrisRESTClient';

import { LOCAL_STORAGE_LANGUAGE_KEY } from 'utils/constants';

// use this value from the local storage to replace the base api url on the stage
// This is necessary so that you can launch (locally) another environment for requests in the deployed project
const LOCAL_BASE_URL = localStorage.getItem('api_url');
const DEFAULT_LANGUAGE = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY);

const BASE_URL =
  process.env.NODE_ENV !== 'production' &&
  LOCAL_BASE_URL &&
  LOCAL_BASE_URL !== ''
    ? LOCAL_BASE_URL
    : String(process.env.REACT_APP_API_URL);

export const altrisRESTClient = new AltrisRESTClient({
  BASE_URL,
  key: 'altris-api',
  defaultLanguage: DEFAULT_LANGUAGE || 'en',
});

export const api = altrisRESTClient.api;
